import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Contact from "../components/contact"
import HeadingBanner from "../components/headingBanner"
import Clifford from "../images/advisors/clifford.png"
import John from "../images/advisors/john.png"
import Ian from "../images/advisors/ian.png"
import Scott from "../images/advisors/scott.png"
import MembersIntro from "../components/membersIntro"
import "../theme/app.scss"

const advisorsData = [
  {
    name: "Clifford M. Samuel",
    designation: "Executive Advisor",
    profile: Clifford,
    content:
      "Clifford Samuel has 25+ years of commercial leadership experience in biopharmaceuticals, with more than two decades at Gilead Sciences, culminating as the Senior VP of Access/Global Patient Services, leading more than 140 countries for Gilead. Motivated by his mission to ensure large-scale access to Gilead’s life-saving medicines, Clifford identified market potential and enabled the delivery of medicines to millions in emerging markets, while significantly increasing revenues. Clifford accomplished this by crafting global strategic partnerships and collaborations with governments, non-governmental organizations, and global generic drug manufacturers, as well as by applying his strong manufacturing, operational, and global regulatory expertise. \nClifford changed the global pharmaceutical landscape when he spearheaded Gilead’s entry to generic licensing agreements with companies in China, India, and South Africa. This created a competitive market for generic HIV and hepatitis B & C medicines. Executed at dramatically reduced prices, his efforts delivered medicines to more than 15 million people in resource-limited countries.",
  },
  {
    name: "Scott Movagero",
    designation: "Advisor",
    profile: Scott,
    content:
      "Scott Mogavero has 25+ years of operational leadership with nearly two decades at General Electric in global leadership roles in commercial, sourcing, manufacturing, IT, business development, and project controls & logistics. He led a global team of 325 employees for wing-to-wing logistics execution for the Gas Power product line. Scott has been recognized as an innovator in the industry delivering results through all economic cycles by being proactive, staying connected, and understanding local market needs yet bringing globally capable solutions.",
  },
]
const AdvisorsPage = () => (
  <Layout>
    <SEO title="Advisors" />
    <HeadingBanner title="Our advisory board" />
    <MembersIntro data={advisorsData} />
    <Banner />
    <Contact />
  </Layout>
)

export default AdvisorsPage
