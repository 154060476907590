import React from "react"
import styles from "./headingBanner.module.scss"

const HeadingBanner = ({ title }) => {
  return (
    <div className={styles.headingBannerContainer}>
      <div className={styles.content}>{title}</div>
    </div>
  )
}

export default HeadingBanner
