import React from "react"
import styles from "./membersIntro.module.scss"

function NewlineText(props) {
  const text = props.text
  const newText = text.split("\n").map((str, index) => (
    <div key={index} className={styles.memberContent}>
      {str}
    </div>
  ))

  return newText
}
const MembersIntro = props => {
  return (
    <div>
      {props.data.map((member, index) => (
        <div className={styles.memberContainer} key={index}>
          <img
            src={member.profile}
            alt="profile-img"
            className={styles.memberImg}
          />
          <div className={styles.memberName}>{member.name}</div>
          <div className={styles.memberDesignation}>{member.designation}</div>
          {/* <div className={styles.memberContent}>{member.content}</div> */}
          <NewlineText text={member.content} />
        </div>
      ))}
    </div>
  )
}

export default MembersIntro
